import React, { ReactElement, useEffect, useState } from 'react';
import FullscreenSpinner from './FullscreenSpinner';

function App(): ReactElement {
  const [ready, setReady] = useState(false);
  const [list, setList] = useState<Array<{name: string; instances: Array<{name: string; subdomain: string; initialized: boolean}>}>>([]);

  useEffect(() => {
    refreshInstances();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const refreshInstances = async () => {
    const response = await fetch(process.env.REACT_APP_API_URL + '/list', {
      mode: 'cors',
    });
    const body = await response.json();

    if(!response.ok){
      alert('Nie można odświeżyć listy kapituł');
      return;
    }

    setReady(true);

    setList(body.data);
  }

  return (<>
    {!ready ? 
      <FullscreenSpinner />
    :
      <div className="container-fluid">
        <div className="row justify-content-center">
          {
            list.map((organization) => {
              return (
                <div className="col-lg-4 col-sm-12">
                  <div className="p-3">
                    <ul className="list-group">
                      <li className="list-group-item list-group-item-info d-flex justify-content-center bg-dark text-center text-white">
                        <h4 className="mb-1 mt-1">{organization.name}</h4>
                      </li>
                      {
                        organization.instances.map((instance) => {
                          const url = new URL(window.location.origin);
                          const baseurl = url.hostname.split(/\./).slice(-2).join('.');
                          return (
                            <li className="list-group-item text-center">
                              {instance.initialized ?
                                  <a className="btn btn-dark btn-block" href={'https://' + instance.subdomain + '.' + baseurl}>{instance.name}</a>
                                :
                                  <button className="btn btn-dark btn-block" disabled>{instance.name}</button>
                              }
                            </li>
                          )
                        })
                      }
                    </ul>
                  </div>
                </div>
              );
            })
          }
        </div>
        <div className="row justify-content-center">
          <div className="col-12 col-lg-6">
            <img src="/imgs/rohis_baner.png" style={{width: "100%"}}/>
          </div>
        </div>
      </div>
    }
  </>);
}

export default App;
